
import { Component, Vue } from 'vue-property-decorator'
import { DataList, PersonList } from '@/types/scheduling'
import { TimeList } from '@/types/onlineStatistics'

@Component

export default class ScheduleDetail extends Vue {
  private disabled = true
  private workerList: Array<{ workerId: string; workerName: string; checked: boolean }> = []
  private workTimeList: TimeList[] = []
  private workTime = ''
  private info: DataList = {
    projectId: '',
    // 排班日期
    schedulingDate: [],
    // 排班人数
    schedulingSum: '',
    // 排班总人数
    workerAmount: '',
    // 绑定设备数
    deviceAmount: ''
  }

  private tableData: PersonList[] = []

  // 在岗时间段
  get workTimeInfo () {
    let data = this.workTimeList.length > 0 ? '' : '--'
    this.workTimeList.forEach((item, index) => {
      data += '时间段' + (index + 1) + ':&nbsp;' + item.startTime + '~' + item.endTime + '&nbsp;&nbsp;&nbsp;'
    })
    return data
  }

  get recordId () {
    return this.$route.params.recordId || ''
  }

  created () {
    this.recordId && this.getDetail()
  }

  // 查询详情
  getDetail () {
    this.$axios.get(this.$apis.recruitUse.selectYhWorkerRecordByRecordId, {
      recordId: this.recordId
    }).then(res => {
      this.info = res || {}
      this.tableData = res.schedulingList
      this.getWorkerList()
      this.getWorkTimeListByProjectId()
    })
  }

  getWorkTable (list: Array<string>) {
    const workers = this.workerList.filter((item) => {
      return list.indexOf(item.workerId) !== -1
    })
    let data = ''
    workers.forEach(item => {
      data += item.workerName + ', '
    })
    return {
      data: data,
      length: workers.length
    }
  }

  // 查询项目工人列表
  getWorkerList () {
    this.$axios.get(this.$apis.recruitUse.selectYhWorkerInfoByPage, {
      projectId: this.info.projectId
    }).then(res => {
      this.workerList = res.list.map((item: { workerId: string; workerName: string }) => {
        return {
          workerId: item.workerId,
          workerName: item.workerName
        }
      })
    })
  }

  // 查询项目在岗班次列表
  getWorkTimeListByProjectId () {
    this.$axios.get(this.$apis.recruitUse.selectYhWorkerRulesByProjectId, {
      projectId: this.info.projectId
    }).then(res => {
      this.workTimeList = res.timeList || []
    })
  }

  getWorkTimeTable (list: Array<string>) {
    const workers = this.workTimeList.filter((item: TimeList) => {
      return list.find((items: string) => items === item.timeId)
    })
    let data = ''
    workers.forEach(item => {
      data += item.startTime + '~' + item.endTime + ' '
    })
    return data
  }
}
